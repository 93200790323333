import "components/reusable/DocumentVerificationReview/DocumentVerificationReview.scss";
import "typography.scss";

import React, { useLayoutEffect, useRef } from "react";

import DocumentImageViewer from "components/reusable/DocumentVerificationReview/DocumentImageViewer";
import DocumentReviewForm from "components/reusable/DocumentVerificationReview/DocumentReviewForm";
import ReviewHeader from "components/reusable/ReviewHeader";

const DocVReview = ({ review, next, completed, callback }) => {
  const smileReference = review.smile_reference;
  const ref = useRef(null);

  const { source, target } = review.images || {};
  const frontIdImage = source?.url || review.source;
  const backIdImage =
    target?.find(({ imageType }) => imageType.valueText === "id_card_back")
      ?.url || review.target;

  useLayoutEffect(() => {
    if (ref.current && !completed) {
      ref.current?.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  }, []);

  return (
    <div
      className="legacy container__content document_review-content dark_bg"
      ref={ref}
    >
      <div className="review-header">
        <ReviewHeader
          title="Document Review"
          review={review}
          completed={completed}
          onNextReview={next}
        />
      </div>
      <div className="grid grid-cols-2 gap-6">
        <div className="review--id-card documents_container">
          <section className="document_front">
            <DocumentImageViewer
              imageUrls={[frontIdImage]}
              imageType="id_card"
              enableBoundingBox
            />
          </section>
          {!backIdImage && (
            <section className="h1 no_document_back">No back of ID</section>
          )}
          {backIdImage && (
            <section className="document_back">
              <DocumentImageViewer
                imageUrls={[backIdImage]}
                imageType="id_card_back"
                enableBoundingBox
              />
            </section>
          )}
        </div>
        <div>
          <DocumentReviewForm
            rejectionReason={smileReference?.internal?.Payload?.RejectionReason}
            review={review}
            completed={completed}
            callback={callback}
            next={next}
          />
        </div>
      </div>
      <ConfidenceResult completed={completed} result={smileReference?.result} />
    </div>
  );
};

const ConfidenceResult = ({ completed, result }) => {
  if (!completed) return null;

  return (
    <div className="block--decision">
      {result && result.ConfidenceValue && (
        <p className={result.ConfidenceValue === "100" ? "success" : "failure"}>
          {result.ResultText}
        </p>
      )}
    </div>
  );
};

export default DocVReview;
